<template>
  <v-container>

    <!-- Skill Test preview -->
    <v-overlay v-if="displaySkillTest">
      <v-card :light="!$vuetify.theme.dark" color="secondary" style="width: 90vw; height: 90vh; overflow-y: scroll !important;">
        <v-card-title>
          {{ displayedSkillTest.name }}{{ ['', ' - APROVADO', ' - RECUSADO'][displayedSkillTest.approvalCode] }}
          <v-spacer/><v-btn @click="toggleSkillTest" depressed small color="accent" class="white--text">FECHAR</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row v-for="(question, qIdx) in Object.values(displayedSkillTest.questions).sort((a, b) => { return a.questionNumber - b.questionNumber })" :key="qIdx">
            <v-col cols="12">
              <question-preview :question-data="question"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-around">
          <v-btn v-if="displayedSkillTest.approvalCode === 0" @click="classifySkillTest(displayedSkillTest.uid, 2)" color="error" depressed>Recusar Teste</v-btn>
          <v-btn v-if="displayedSkillTest.approvalCode !== 1" @click="classifySkillTest(displayedSkillTest.uid, 1)" color="success" depressed>Aprovar Teste</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Register Freela card -->
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" :style="$vuetify.breakpoint.mdAndUp ? 'border-right: 1px solid darkgray;' : ''">
        <v-card :flat="registerCardIsFlat" @focusin="registerCardIsFlat=false" @focusout="registerCardIsFlat=true" style="height: 300px;">
          <v-card-title>Registrar Novo Freelancer</v-card-title>
          <v-card-text class="d-flex flex-column justify-center">
            <v-text-field v-model="displayName" :flat="nameFlat" @focusin="nameFlat=false" @focusout="nameFlat=true"
                          solo color="accent" placeholder="nome completo" hide-details class="mb-2"></v-text-field>
            <v-text-field v-model="email" :flat="emailFlat" @focusin="emailFlat=false" @focusout="emailFlat=true"
                          solo color="accent" placeholder="email" hide-details class="mb-2"></v-text-field>
            <v-text-field v-model="password" :flat="passwordFlat" @focusin="passwordFlat=false" @focusout="passwordFlat=true"
              solo color="accent" placeholder="senha" hide-details class="mb-2"></v-text-field>
            <v-btn :disabled="!displayName || !email || !password || password.length < 6 || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())) || displayName.length < 4"
                   @click="registerNewUser" color="primary" depressed>Registrar</v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Skill test list card -->
      <v-col cols="12" xl="6" lg="6" md="6">
        <v-card flat style="height: 300px;">
          <v-card-title>Processo Seletivo</v-card-title>
          <v-card-text style="overflow-y: scroll; overflow-x: hidden; height: 14rem;" class="text--primary">
            <v-row dense><v-col cols="12">Pendentes: </v-col></v-row>
            <v-row dense v-for="test in sortedPendingSkillTests" :key="test.uid">
              <v-col cols="12">
                <div style="display: flex; justify-content: space-between;">
                  <span class="text-truncate" style="width: calc(33%);">{{ test.name }}</span>
                  <v-btn @click="toggleSkillTest(test.uid, test.approvalCode)" small depressed color="accent" class="white--text">
                    Acessar Teste
                  </v-btn>
                  <span style="font-size: smaller; white-space: nowrap;">{{ timestampToDate(test.date) }}</span>
                </div>
                <v-divider style="border-color: darkgray; margin-top: 4px;"/>
              </v-col>
            </v-row>
            <v-row dense v-if="!displayApprovedSkillTests"><v-col cols="12" class="text-center">
              <v-btn @click="loadApprovedTests" color="accent" text class="text-capitalize">Carregar testes aprovados</v-btn>
            </v-col></v-row>
            <template v-else>
              <v-row dense><v-col cols="12">
                <v-divider style="border-color: darkgray; margin-top: 4px;"/>
                Aprovados:
              </v-col></v-row>
              <v-row dense v-for="test in Object.values(this.approvedSkillTests).sort((a, b) => { return a.date - b.date })" :key="test.uid">
                <v-col cols="12">
                  <div style="display: flex; justify-content: space-between;">
                    <span class="text-truncate" style="width: calc(33%);">{{ test.name }}</span>
                    <v-btn @click="toggleSkillTest(test.uid, test.approvalCode)" small depressed color="accent" class="white--text">
                      Acessar Teste
                    </v-btn>
                    <span style="font-size: smaller; white-space: nowrap;">{{ timestampToDate(test.date) }}</span>
                  </div>
                  <v-divider style="border-color: darkgray; margin-top: 4px;"/>
                </v-col>
              </v-row>
            </template>
            <v-row dense v-if="!displayRefusedSkillTests"><v-col cols="12" class="text-center">
              <v-btn @click="loadRefusedTests" color="accent" text class="text-capitalize">Carregar testes recusados</v-btn>
            </v-col></v-row>
            <template v-else>
              <v-row dense><v-col cols="12">
                <v-divider style="border-color: darkgray; margin-top: 4px;"/>
                Recusados:
              </v-col></v-row>
              <v-row dense v-for="test in Object.values(this.refusedSkillTests).sort((a, b) => { return a.date - b.date })" :key="test.uid">
                <v-col cols="12">
                  <div style="display: flex; justify-content: space-between;">
                    <span class="text-truncate" style="width: calc(33%);">{{ test.name }}</span>
                    <v-btn @click="toggleSkillTest(test.uid, test.approvalCode)" small depressed color="accent" class="white--text">
                      Acessar Teste
                    </v-btn>
                    <span style="font-size: smaller; white-space: nowrap;">{{ timestampToDate(test.date) }}</span>
                  </div>
                  <v-divider style="border-color: darkgray; margin-top: 4px;"/>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Exam preview -->
    <v-overlay v-if="displaySubmittedExam">
      <v-card :light="!$vuetify.theme.dark" color="secondary" style="width: 90vw; height: 90vh; overflow-y: scroll !important;">
        <v-card-title>
          {{ displayedExamReport.examName }} | {{ displayedExamReport.authorName }}<v-spacer/>
          <v-btn @click="toggleDisplayedExam" depressed small color="accent" class="white--text">FECHAR</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row v-for="question in displayedExamQuestions.slice().sort((a, b) => { return a[1].questionNumber - b[1].questionNumber })" :key="question[0]">
            <v-col cols="12">
              <question-preview :question-data="question[1]"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- PIX preview -->
    <v-overlay v-if="displayedPix">
      <v-card color="accent" style="width: 400px;">
        <v-card-title>
          <p>Chave: {{ displayedPix }}</p><br><br>
          <p>Titular: {{ displayedTitular }}</p><v-spacer/>
        </v-card-title>
        <v-card-text class="d-flex justify-center align-center">
          <v-btn @click="toggleFreelaAccount" depressed color="error" class="white--text">FECHAR</v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Exams list card -->
    <v-row>
      <v-col cols="12">
        <v-card color="primary" class="white--text">
          <v-card-title>
            Provas Submetidas
            <v-switch v-model="isDateSorting" color="accent" label="Ordenar por data" hide-details dark class="ml-4 pt-0 mt-0"/>
            <v-spacer/>
            <v-btn v-if="!sortedSubmittedExams.some((ex)=>ex.isPaid)" @click="loadApprovedExams" depressed small color="accent"
                   class="white--text text-capitalize">Carregar Exames Aprovados</v-btn>
          </v-card-title>
          <v-card-text v-if="sortedSubmittedExams.length">
            <v-list color="primary" rounded class="lighten-1">
              <v-list-item v-for="(exam, eIdx) in sortedSubmittedExams" :key="eIdx">
                <v-list-item-content style="position: relative;" :style="exam.isPaid ? 'opacity: 0.5;' : ''">
                  <v-list-item-title v-text="exam.examName"/>
                  {{ exam.authorName }} - {{ timestampToDate(exam.submissionTime) }}
                  <v-btn @click="toggleFreelaAccount(exam.authorId)" :style="`right: ${exam.isPaid ? '100px' : '250px'};`"
                         style="position: absolute;" right color="error" small depressed>PIX</v-btn>
                  <v-btn v-if="!exam.isPaid" @click="approveExam(exam.eid)"
                         style="position: absolute; right: 100px;" right color="primary" small depressed>Aprovar exame</v-btn>
                  <v-btn @click="toggleDisplayedExam(exam)" style="position: absolute; right: 0;"
                         class="white--text" right color="accent" small depressed>Acessar</v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { db, fieldValue, functions } from "@/main";
import QuestionPreview from "@/components/QuestionPreview";
export default {
  name: "Admin",
  components: {QuestionPreview},
  data() { return {
    displayName: null, email: null, password: null,
    registerCardIsFlat: true, emailFlat: true, passwordFlat: true, nameFlat: true,
    pendingSkillTests: {}, displaySkillTest: false, displayedSkillTest: null,
    approvedSkillTests: {}, refusedSkillTests: {}, displayApprovedSkillTests: false, displayRefusedSkillTests: false,
    displaySubmittedExam: false, displayedExamQuestions: null, displayedExamReport: null,
    displayedPix: null, displayedTitular: null,
    submittedExams: {
      /*
       *  gcloud firestore export gs://upwork-tex-job.appspot.com/backup-2022-05-20
       *  gsutil -m cp -r gs://upwork-tex-job.appspot.com/backup-2022-05-20 .
       */
    },
    isDateSorting: true,
  } },
  computed: {
    sortedSubmittedExams() {
      const unpaidArray = [];
      const paidArray = [];
      for (const examId of Object.keys(this.submittedExams)) {
        const exam = this.submittedExams[examId];
        exam.isPaid ? paidArray.push({ eid: examId, ...exam }) : unpaidArray.push({ eid: examId, ...exam });
      }
      if (this.isDateSorting) {
        unpaidArray.sort((a, b) => { return a.submissionTime - b.submissionTime });
        paidArray.sort((a, b) => { return a.submissionTime - b.submissionTime });
      } else {
        unpaidArray.sort((a, b) => { return a.examName.localeCompare(b.examName) });
        paidArray.sort((a, b) => { return a.examName.localeCompare(b.examName) });
      }
      return [...unpaidArray, ...paidArray];
    },
    sortedPendingSkillTests() { return Object.values(this.pendingSkillTests).sort((a, b) => { return a.date - b.date }); }
  },
  created() {
    db.collection('SubmissionReports').where('isPaid', '==', false).get()
      .then((snap) => {
        snap.forEach((exam) => { this.$set(this.submittedExams, exam.id, exam.data()); });
      });
    db.collection('SkillTests').where('approvalCode', '==', 0).get()
      .then((snap) => {
        snap.forEach((test) => { this.$set(this.pendingSkillTests, test.id, test.data()); });
      });
  },
  methods: {
    timestampToDate(d) {
      const tDate = new Date(d);
      return `${('0' + tDate.getHours()).substr(-2)}:${('0' + tDate.getMinutes()).substr(-2)} - ${('0' + tDate.getDate()).substr(-2)}/${('0' + (tDate.getMonth() + 1)).substr(-2)}`;
    },
    toggleSkillTest(uid, approvalCode) {
      if (!this.displaySkillTest) {
        switch (approvalCode) {
          case 0: this.displayedSkillTest = this.pendingSkillTests[uid]; break;
          case 1: this.displayedSkillTest = this.approvedSkillTests[uid]; break;
          case 2: this.displayedSkillTest = this.refusedSkillTests[uid]; break;
        }
        this.displaySkillTest = true;
      }
      else { this.displayedSkillTest = null; this.displaySkillTest = false }
    },
    loadApprovedTests() {
      db.collection('SkillTests').where('approvalCode', '==', 1).get()
        .then((snap) => { snap.forEach((test) => { this.$set(this.approvedSkillTests, test.id, test.data()); }); })
        .finally(()=>this.displayApprovedSkillTests=true);
    },
    loadRefusedTests() {
      db.collection('SkillTests').where('approvalCode', '==', 2).get()
        .then((snap) => { snap.forEach((test) => { this.$set(this.refusedSkillTests, test.id, test.data()); }); })
        .finally(()=>this.displayRefusedSkillTests=true);
    },
    loadApprovedExams() {
      db.collection('SubmissionReports').where('isPaid', '==', true).get()
        .then((snap) => {
          snap.forEach((exam) => { this.$set(this.submittedExams, exam.id, exam.data()); });
        });
    },
    async classifySkillTest(uid, code) {
      this.$store.commit('enableLoadingState');
      try {
        if (code === 1) { // approve
          const approveFreela = httpsCallable(functions, 'approveFreela');
          const res = await approveFreela({ uid: uid }); console.log(res);
          this.$set(this.approvedSkillTests, uid, this.pendingSkillTests[uid]);
        } else if (code === 2) { // refuse
          await db.collection("SkillTests").doc(uid).update({approvalCode: 2});
          this.$set(this.refusedSkillTests, uid, this.pendingSkillTests[uid]);
        }
        this.$delete(this.pendingSkillTests, uid);
      } catch (e) { alert(`Ocorreu um erro: ${e.code} - ${e.message}.`); console.log(e); }
      finally { this.toggleSkillTest(); this.$store.commit('disableLoadingState'); }
    },
    async toggleDisplayedExam(examData) {
      if (this.displaySubmittedExam) {
        this.displaySubmittedExam = false; this.displayedExamQuestions = null;
        this. displayedExamReport = null; return null;
      }
      this.$store.commit('enableLoadingState');
      try {
        const questions = [];
        const snapshot = await db.collection('AdminQuestions').where('examID', '==', examData.eid).get();
        if (!snapshot.empty) {
          snapshot.forEach(doc => { questions.push([doc.id, doc.data()]); });
          this.displayedExamQuestions = questions; this.displayedExamReport = examData; this.displaySubmittedExam = true;
        } else { alert('Nenhuma questão encontrada!'); }
      } catch (e) { alert(`Ocorreu um erro: ${e.code} - ${e.message}.`); console.log(e); }
      finally { this.$store.commit('disableLoadingState'); }
    },
    async toggleFreelaAccount(uid) {
      if (this.displayedPix) { this.displayedTitular = null; this.displayedPix = null; return null; }
      this.$store.commit('enableLoadingState');
      try {
        const doc = await db.collection('FreelaAccounts').doc(uid).get();
        if (!doc.exists) { alert('PIX não cadastrado!'); }
        else { this.displayedPix = doc.data().pixKey; this.displayedTitular = doc.data().accountOwner; }
      } catch (e) { alert(`Ocorreu um erro: ${e.code} - ${e.message}.`); console.log(e); }
      finally { this.$store.commit('disableLoadingState'); }
    },
    async approveExam(eid) {
      this.$store.commit('enableLoadingState');
      try {
        await db.collection('SubmissionReports').doc(eid).update({ isPaid: true });
        await db.collection('FreelaAccounts').doc(this.submittedExams[eid].authorId).set({
          paidExams: fieldValue.arrayUnion(eid)
        }, { merge: true });
        this.submittedExams[eid].isPaid = true;
      } catch (e) { alert(`Ocorreu um erro: ${JSON.stringify(e)}`); }
      finally { this.$store.commit('disableLoadingState'); }
    },
    async registerNewUser() {
      this.$store.commit('enableLoadingState');
      try {
        const createUser = httpsCallable(functions, 'createUser');
        const res = await createUser({ email: this.email, password: this.password, displayName: this.displayName });
        console.log(res); this.email = null; this.displayName = null;
      } catch (e) { alert(`Ocorreu um erro: ${e.code} - ${e.message}.`); console.log(e);  }
      finally { this.password = null; this.$store.commit('disableLoadingState'); }
    }
  }
}
</script>

<style>
  .theme--dark.v-input--switch .v-input--switch__thumb { color: #7E5A9B !important; }
</style>
