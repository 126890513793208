<template>
  <v-card color="card">
    <v-card-title class="primary white--text mb-1" style="opacity: 1.0; font-size: 0.8rem; word-break: break-all !important;
                                          padding-top: 0 !important; padding-left: 10px !important; padding-bottom: 0 !important;">
      <v-row dense>
        <v-col v-if="!showTags" cols="12" class="d-flex" style="cursor: pointer !important;">
          <div class="d-inline-flex align-center justify-space-between flex-grow-1">
            <span>
              {{ `Q${questionData.questionNumber} | ${ questionData.meta.institution } | ${ questionData.meta.year } | ${questionData.meta.subject} ${questionData.pendingImages ? ' - TEM IMAGENS PENDENTES' : ''}` }}
            </span>
            <v-btn x-small @click="toggleInspectionMode" color="accent" depressed>{{ inspectionMode ? 'Voltar ao Normal' : 'Inspecionar Questões' }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-overlay absolute :value="textOverlay" opacity="0.85" color="secondary" class="restrained-overlay">
        <v-card color="primary" style="margin: 1em; text-align: justify; height: fit-content;">
          <v-card-title>
            <v-btn x-small icon style="position: absolute; right: 1em; top: 1em;" @click="textOverlay = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="d-block enunciado tex2jax_process">{{ questionData.relatedText }}</v-card-text>
        </v-card>
      </v-overlay>
      <v-container :class="{'desktop-container-padding': $vuetify.breakpoint.lgAndUp}">
        <v-row dense v-if="questionData.relatedText" class="d-flex flex-row" style="margin-bottom: 1vh">
          <v-btn outlined small @click="displayTextOverlay">Ler Texto</v-btn>
        </v-row>
        <v-row v-if="parsedEnunciados[0]" style="font-size: 1rem; text-align: justify" class="d-block enunciado tex2jax_process text--primary"
               v-html="parsedEnunciados[0]"/>
        <v-row v-for="(itemEnunciado, itemEnunciadoIndex) in parsedEnunciados[1]" :key="itemEnunciadoIndex">
          <v-container v-if="itemEnunciado" class="d-block enunciado tex2jax_process text--primary" style="font-size: 1rem" v-html="itemEnunciado"/>
          <v-row v-if="parsedGabaritos[1][itemEnunciadoIndex]" dense style="margin: -12px 20px 0 20px; overflow-x: auto; border: darkgray solid 0; border-top-width: thin; border-bottom-width: thin;">
            <v-col cols="12" xl="9" lg="9">
              <v-container class="d-block enunciado tex2jax_process text--primary"
                           style="text-align: center; overflow-x: auto;" v-html="'Gabarito: ' + parsedGabaritos[1][itemEnunciadoIndex]"/>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-if="questionData.dados" style="overflow-x: auto;" class="ma-2">
          <v-container style="border: 1px solid #7E5A9B; width: fit-content; height: fit-content"
                       class="enunciado tex2jax_process text--primary" :class="{'desktop-dados-alignment': $vuetify.breakpoint.lgAndUp}"
                       v-html="questionData.dados.match(/(?:\$+[^$\\]*(?:\\[\s\S][^$\\]*)*\$+|[^,])+/g).map(s => s.trim()).join('<br/>')"/>
        </v-row>
        <br v-if="parsedAlternativas.length>0 || questionData.dados">
        <v-row v-if="parsedAlternativas.length>0" style="margin-bottom: -2vh">
          <v-col cols="12" xl="10" lg="10" :class="{'desktop-alternatives-padding': $vuetify.breakpoint.lgAndUp}">
            <v-radio-group :disabled="!parsedGabaritos[0]" v-model="alternativesModel"
                           style="margin-top: -2.4vh; padding-top: 0" class="alternative-group">
              <v-radio :on-icon="alphabetIcons[altIndex]" :off-icon="alphabetIcons[altIndex]" style="padding-bottom: 1vh"
                       class="d-block enunciado tex2jax_process text--primary" v-for="(alternative, altIndex) in parsedAlternativas" :ripple="false"
                       :key="altIndex" :value="alphabet[altIndex]" :label="alternative.value" :color="alphabet[altIndex]?'accent':'tertiary'"/>
            </v-radio-group>
          </v-col>
          <v-col cols="12" xl="2" lg="2" v-if="!parsedGabaritos[0]" style="position: relative; margin-top: -3vh; margin-bottom: 2vh;" class="d-flex align-end justify-center justify-lg-end">
            <v-btn disabled right>Sem Gabarito</v-btn>
          </v-col>
          <v-col cols="12" xl="2" lg="2" v-if="parsedGabaritos[0] && alternativesModel" style="position: relative; margin-top: -3vh; margin-bottom: 2vh;" class="d-flex align-end justify-center justify-lg-end">
            <v-btn v-if="!answerResponse" right color="accent">Responder</v-btn>
            <v-alert v-else dense dark :icon="answerResponse.icon" style="margin-bottom: 0;"
                     :style="$vuetify.breakpoint.lgAndUp?{'white-space': 'nowrap', 'position': 'absolute', 'right': '0', 'background-color': `${answerResponse.color}`}:{'background-color': `${answerResponse.color}`}">
              {{ answerResponse.message }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="parsedGabaritos[0]" dense style="margin: 0; min-height: 55px; overflow-x: auto; border-top: darkgray solid thin;">
          <v-col cols="12" xl="9" lg="9">
            <v-container class="d-block enunciado tex2jax_process text--primary"
                         style="text-align: center; overflow-x: auto;" v-html="'Gabarito: ' + parsedGabaritos[0]"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'QuestionPreview',
  props: {
    questionData: { type: Object, default: null },
  },
  data: () => ({
    showTags: false,
    textOverlay: false,
    alternativesModel: null,
    alphabet: ['a)', 'b)', 'c)', 'd)', 'e)', 'f)', 'g)'],
    answerResponse: null,
    sectionVisible: -1,
    inspectionMode: false,
  }),
  computed: {
    parsedEnunciados() { return JSON.parse(this.questionData.enunciados) },
    parsedGabaritos()  { return JSON.parse(this.questionData.gabaritos) },
    parsedAlternativas() { return JSON.parse(this.questionData.alternatives) },
    alphabetIcons() {
      if (this.questionData.flagCE) { return ['mdi-alpha-c-circle', 'mdi-alpha-e-circle']; }
      else { return ['mdi-alpha-a-circle', 'mdi-alpha-b-circle', 'mdi-alpha-c-circle', 'mdi-alpha-d-circle', 'mdi-alpha-e-circle', 'mdi-alpha-f-circle', 'mdi-alpha-g-circle']; }
    }
  },
  mounted() { this.reRender(); },
  methods: {
    reRender() { if ('MathJax' in window) { this.$nextTick(function() { window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, document.body]) }) } },
    displayTextOverlay() { this.textOverlay = !this.textOverlay; this.reRender(); },
    toggleInspectionMode() {
      window.MathJax.Hub.Queue(this.inspectionMode ? ["Reprocess", window.MathJax.Hub] : ()=>{
        const HTML = window.MathJax.HTML, jax = window.MathJax.Hub.getAllJax();
        for (let i = 0, m = jax.length; i < m; i++) {
          let script = jax[i].SourceElement(), tex = jax[i].originalText;
          if (script.type.match(/display/)) {tex = "$$"+tex+"$$"}
          else {tex = "$"+tex+"$"}
          jax[i].Remove();
          let preview = script.previousSibling;
          if (preview && preview.className === "MathJax_Preview") {
            preview.parentNode.removeChild(preview);
          }
          preview = HTML.Element("span",{className:"MathJax_Preview"},[tex]);
          script.parentNode.insertBefore(preview,script);
        }
      }); this.inspectionMode = !this.inspectionMode;
    }
  },
}
</script>

<style scoped>
.desktop-dados-alignment { margin-left: 0; }
.desktop-container-padding { padding: 20px 40px; }
.desktop-alternatives-padding { padding-left: 30px; }
.mobile-button-label { display: none; }
.restrained-overlay { align-items: start; text-align: center; text-align: -webkit-center; }
.restrained-overlay >>> .v-overlay__content { display: flex; overflow-y: auto; height: 100%; }
.question-action.theme--light { color: rgba(0,0,0,0.6) !important; }
.question-action.theme--dark { color: rgba(255,255,255,0.7) !important; }
</style>
